<template>
  <div class="derbygo">
    <div class="login-grid">
      <div class="login-side">
        <img :src="require('assets/derbygo/derbygo-logo-blue.svg')" class="login-logo">
        <img :src="require('assets/derbygo/city-outline-blue.png')" class="login-img">

        <router-view />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LayoutLogin'
}
</script>

<style lang="stylus" scoped>
  @import '../../../styles/login.styl'
  .login-img
    margin 0 30px
    width calc(60% - 60px)
  .login-logo
    margin 0 33%
    max-width 33%
  @media screen and (min-width: 641px)
    .login-grid
      overflow-y scroll
      grid-template-columns minmax(390px, 40%) auto
      background-image url('~assets/derbygo/login.png')
      background-size contain
  @media screen and (max-width: 641px)
    .login-side
      grid-template-rows none
    .login-img
      width calc(100% - 60px)
  </style>
